* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    font-family: 'QuickSand', sans-serif;
    transition: all .4s;
    scrollbar-width: thin;
    scroll-behavior: smooth;

    transition-property: background-color, color, border-color, box-shadow; /* specify properties */
    transition-duration: 0.4s;
    transition-timing-function: ease;
}

.gold, .gold::after {
    position: relative;
    color: #fff;
    transition: 1s all;
}

.gold::after {
    content: "";
    position: absolute;
    bottom: -5px;
    left: 50%;
    transform: translateX(-50%);
    width: 0;
    height: 0;
    border-radius: 50%;
    background-clip: border-box;
    transition: .7s all;
}

.gold:hover::after {
    bottom: 7px;
    width: 4px;
    height: 4px;
    border-radius: 50%;
}

a {
    text-decoration: none;
    color: #fff;
    text-transform: uppercase;
    font-size: 15px;
}

.subnavbtn {
    display: grid;
    grid-template-rows: 30px 20px;
    padding: 10px calc(50vw - 100px);
    line-height: 57.1428571429px;
}

.subnavbtn:hover {
    color: #fff;
    transition: all 0.2s;
}

nav {
    margin-top: 10px;
    height: 50px;
    width: calc(100vw - 20px);
    position: fixed;
    z-index: 10 !important;

    background: rgba(0, 0, 0, 0.2);
    backdrop-filter: blur(10px);
    border-radius: 10px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    top: 0;
}

.flotte-active {
    display: none;
}

nav ul li {
    list-style-type: none;
    height: 60px;
    text-align: center;
    overflow: hidden;
}

.nav-contact a {
    height: 25px;
}

.navbar {
    display: none;
    position: static;
    overflow-y: scroll;
}

#toggler:checked + .navbar {
    display: block;
}

nav input {
    display: none;
}

nav label {
    cursor: pointer;
    text-align: center;
}

.navbar > ul > label {
    height: 50px;
    display: grid;
    padding-top: 7.5px;
    padding-bottom: 7.5px;
    grid-template-rows : 15px 20px;
    text-transform: uppercase;
    font-size: 15px;
    color: #fff;
}

.navbar > ul > label:hover {
    color: #fff;
    transition: all .2s;
}

.navbar > ul > li > a {
    line-height: 50px;
    border-radius: 5px;
    padding: 8px 25px;
}

.navbar > div > div {
    height: 400px;
    position: relative;
}

.navbar > div {
    height: 400px;
    position: relative;
}

.container {
    height: 80px;
    position: relative;
}

.center-verticaly {
    margin: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}

.nav-contact {
    text-align: center;
}

.material-symbols-outlined {
    font-variation-settings:
    'FILL' 0,
    'wght' 300,
    'GRAD' 0,
    'opsz' 50;
    display: block;
}

.wrapper {
    overflow: hidden;
}

.material-symbols-outlined {
    color: #fff;
}

#menu-icon {
    padding-top: 7.5px;
    padding-bottom: 7.5px;
    font-size: 35px;
}

.navbar ul li:last-child {
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
}

/* Initial state of the ul */
.navbar ul {
    transform: translateY(-100%);
    animation: slideDown 1s ease forwards;
    background: rgba(255, 255, 255, 0.1);
    backdrop-filter: blur(10px);
    border-radius: 5px;
    border: 1px solid rgba(255, 255, 255, 0.1);
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    transition: transform 0.4s ease-out;
}

.navbar > ul > li > a:hover {
    transform: scale(0.9);
}

@keyframes slideDown {
    to {
        transform: translateY(0);
    }
}


@media screen and (min-width: 1400px) {

    .navbar ul li:last-child {
        border-bottom-left-radius: 5px;
        border-bottom-right-radius: 5px;
    }

    a {
        text-decoration: none;
        color: #fff;
    }

    nav {
        opacity: 95%;
        display: block;
        height: 0px;
        width: calc(100vw - 20px);
        position: fixed;
        box-sizing: initial;
        z-index: 10 !important;

        background: 0;
        backdrop-filter: 0;
        border-radius: 0;
        box-shadow: 0;
    }

    nav ul li {
        height: 50px;
    }

    nav ul {
        border: none;
    }

    nav .navbar ul {
        display: flex;
        justify-content: space-around;
        padding: 4.5px 0px;
    }

    .nav-contact a {
        height: 25px;
    }

    .navbar a {
        padding: 15px 0px;
        line-height: 50px;
    }

    .navbar > ul > li > a {
        width: 220px;
        display: inline-block;
        padding: 8px 25px;
        line-height: 32px;
        border-radius: 5px;
    }

    .navbar > ul > li > a:hover {
        transform: scale(0.95);
    }

    .container {
        height: 50px;
        position: relative;
    }

    .nav-contact {
        text-align: center;
    }

    .nav-button {
        display: none;
    }

    .nav-contact {
        display: block;
    }

    .navbar > ul > label {
        display: none;
    }

    .navbar {
        display: block;
        overflow: hidden;
    }

    nav label {
        display: none;
    }

    .flotte-label {
        display: block;
    }

    nav input {
        display: none;
    }

    .wrapper {
        overflow: hidden;
        z-index: 1 !important;
    }
}