.borne-container {
    background: rgba(255, 255, 255, 0.2);
    backdrop-filter: blur(10px);
    border-radius: 10px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    padding: 20px;
    margin: 20px;
    color: #000;
    font-family: 'Arial', sans-serif;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.borne-feature {
    text-align: left;
    margin-bottom: 10px;
}

.borne-title {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 15px;
}

.gallery-container {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    gap: 10px;
    padding: 20px;
}

.gallery-image {
    width: 100%;
    border-radius: 8px;
    overflow: hidden;
}

.video-card {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
}

.video-card video {
    width: auto;
    height: 450px;
}