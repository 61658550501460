.pricing-section {
    text-align: center;
    padding: max(5vw, 25px);
    color: white;

    background: rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(10px);
    border-radius: 10px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    margin-top: 100px;
}

.pricing-section h4 {
    text-decoration: underline;
}

.cards-container {
    margin: 20px 0px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    gap: 20px;
}

.card {
    background: rgba(255, 255, 255, 0.1);
    backdrop-filter: blur(10px);
    border-radius: 10px;
    border: 1px solid rgba(255, 255, 255, 0.2);
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    padding: 20px;
    margin: 0 20px;
    flex: 1;
    min-width: 300px;
    max-width: calc(25% - 40px);
}

.card h3 {
    margin-bottom: 15px;
}

.card ul {
    list-style: none;
    padding: 0;
    text-align: left;
}

.card li {
    border-bottom: 1px solid rgba(255, 255, 255, 0.2);
    margin-bottom: 10px;
    padding: 5px 0;
}

/* Media query for mobile devices */
@media (max-width: 768px) {
    .card {
        max-width: calc(50% - 40px); /* Adjust max width for two cards in a row */
    }
}

/* Media query for extra small mobile devices */
@media (max-width: 480px) {
    .card {
        max-width: 100%; /* Each card takes full width of the screen */
    }
}

.card-image-container {
    padding: 20px 10px;
}