body {
  	font-family: "Exo 2", sans-serif;
}

.glassmorphism {
    background: rgba(0, 0, 0, 0.2);
    backdrop-filter: blur(10px);
    border-radius: 10px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.head {
	max-width: 1200px;
	max-height: 800px;
	margin: 0 auto;
	display: flex;
	align-items: center;
	justify-content: center;
	height: calc(100vh - 4rem);
}

.header {
	padding: 80px;
	max-width: calc(100vw - 20px);
}

.title-wrapper {
	display: grid;
	align-items: center;
	justify-content: center;
  	transform: skew(0, -10deg);
}

.top-title {
	order: 1;
	text-align: center;
	display: block;
	color: #fff;
	font-size: clamp(1rem, max(5vw, 25px), 1.5rem);
	margin-bottom: 1rem;
	padding-right: 2rem;
}

.bottom-title {
	order: 3;
	text-align: center;
	display: block;
	color: #fff;
	font-size: clamp(1rem, max(5vw, 25px), 1.5rem);
	margin-top: 1.5rem;
	padding-left: 2rem;
}

.sweet-title {
	order: 2;
	color: #ffd495;
	font-weight: 900;
	text-transform: uppercase;
	font-size: clamp(3rem, 14vw, 6rem);
	line-height: 0.75em;
	text-align: center;
	text-shadow: 3px 1px 1px #cb9b51, 2px 2px 1px #fff, 4px 2px 1px #cb9b51,
				3px 3px 1px #000, 5px 3px 1px #cb9b51, 4px 4px 1px #000,
				6px 4px 1px #cb9b51, 5px 5px 1px #000, 7px 5px 1px #cb9b51,
				6px 6px 1px #000, 8px 6px 1px #cb9b51, 7px 7px 1px #000,
				9px 7px 1px #cb9b51;

	span {
		display: block;
		position: relative;

		&:before {
			content: attr(data-text);
			position: absolute;
			text-shadow: 2px 2px 1px #1E1E1E, -1px -1px 1px #000,
						-2px 2px 1px #1E1E1E, 1px -1px 1px #000;
			z-index: 1;
		}

		&:nth-child(1) {
			padding-right: 2.25rem;
		}

		&:nth-child(2) {
			padding-left: 2.25rem;
		}
	}
}

@media screen and (min-width: 1400px) {
	.sweet-title {
		order: 2;
		color: #ffd495;
		font-weight: 900;
		text-transform: uppercase;
		font-size: clamp(3rem, 10vw, 6rem);
		line-height: 0.75em;
		text-align: center;
		text-shadow: 3px 1px 1px #cb9b51, 2px 2px 1px #fff, 4px 2px 1px #cb9b51,
					3px 3px 1px #000, 5px 3px 1px #cb9b51, 4px 4px 1px #000,
					6px 4px 1px #cb9b51, 5px 5px 1px #000, 7px 5px 1px #cb9b51,
					6px 6px 1px #000, 8px 6px 1px #cb9b51, 7px 7px 1px #000,
					9px 7px 1px #cb9b51;

		span {
			display: block;
			position: relative;

			&:before {
				content: attr(data-text);
				position: absolute;
				text-shadow: 2px 2px 1px #1E1E1E, -1px -1px 1px #000,
							-2px 2px 1px #1E1E1E, 1px -1px 1px #000;
				z-index: 1;
			}

			&:nth-child(1) {
				padding-right: 2.25rem;
			}

			&:nth-child(2) {
				padding-left: 2.25rem;
			}
		}
	}

	.top-title {
		order: 1;
		text-align: center;
		display: block;
		color: #fff;
		font-size: clamp(1rem, 4vw, 1.5rem);
		margin-bottom: 1rem;
		padding-right: 2rem;
	}

	.bottom-title {
		order: 3;
		text-align: center;
		display: block;
		color: #fff;
		font-size: clamp(1rem, 4vw, 1.5rem);
		margin-top: 1.5rem;
		padding-left: 2rem;
	}
}