.cadre-section {
    background: rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(10px);
    border-radius: 10px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: max(5vw, 25px);
    margin-top: 100px;
    color: white;
    overflow-x: auto;
}

.cadre-section h2 {
    margin-bottom: 1em;
}

.cadre-section p {
    margin: auto;
    line-height: 1.6;
    max-width: 1000px;
}

.cadre-grid {
    display: flex;
    flex-wrap: nowrap;
    width: 100%;
    margin-top: 20px;
    overflow-y:hidden;
    overflow-x: auto;
    align-items: center;
    padding: 20px 30px;
    background: rgba(255, 255, 255, 0.1);
    backdrop-filter: blur(10px);
    border-radius: 10px;
    border: 1px solid rgba(255, 255, 255, 0.2);
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
}

.cadre-item {
    flex: 0 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 10px;
    height: 350px;
    transition: transform 0.3s ease;
}

.cadre-item:hover {
    transform: scale(1.15);
    z-index: 15;
}
