.event {
    background: rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(10px);
    border-radius: 10px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    margin-top: 100px;
    padding: max(5vw, 25px);
}

.event h2 {
    color: white;
    text-align: center;
    margin-bottom: 20px;
}

.image-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
    gap: 1rem;
    padding: 1rem;
    justify-items: center;
    align-items: center;
}

.image-item {
    cursor: pointer;
    border-radius: 10px;
    transition: box-shadow 0.3s ease;
    max-width: 300px;
}

.image {
    width: 100%;
    border-radius: 10px;
    object-fit: cover;
    transition: transform 0.3s ease;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.image-item.selected {
    transform: scale(1.1);
}

.title {
    text-align: center;
    margin-top: 8px;
    color: white;
    font-size: 1rem;
}

.event-details {
    margin-top: 20px;
}

.event-details h3 {
    text-align: center;
    color: white;
    line-height: 50px;
}

.event-details p {
    margin: auto;
    padding: 1rem;
    max-width: 1000px;
    color: white;
    line-height: 1.4;
}
