.story-section {
    background: rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(10px);
    border-radius: 10px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: max(5vw, 25px);
    margin-top: 100px;
    color: white;
}

.story-section h2 {
    margin-bottom: 1em;
}

.story-section p {
    margin: auto;
    line-height: 1.6;
    max-width: 1000px;
}
