.glass-form {
    background: rgba(255, 255, 255, 0.1);
    backdrop-filter: blur(10px);
    border-radius: 10px;
    border: 1px solid rgba(255, 255, 255, 0.2);
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    padding: 2rem;
    max-width: 500px;
    margin: auto;
    color: white;
}

.glass-form-container p {
    margin: 40px;
    color: white;
}

.glass-form-container span {
    font-weight: 800;
}

.glass-form form {
    display: flex;
    flex-direction: column;
}

.input-group {
    margin-bottom: 1rem;
}

.input-group label {
    display: block;
    margin-bottom: 0.5rem;
}

.input-group input[type="text"],
.input-group input[type="email"],
.input-group input[type="tel"],
.input-group input[type="number"],
.input-group input[type="date"],
.input-group textarea {
    width: 100%;
    padding: 0.5rem;
    border: 1px solid rgba(255, 255, 255, 0.2);
    border-radius: 5px;
    background-color: rgba(255, 255, 255, 0.1);
    color: white;
}

.input-group input[type="radio"] {
    margin-right: 0.5rem;
}

.input-group textarea {
    max-width: 100%;
}

button {
    padding: 1rem;
    border: none;
    border-radius: 5px;
    background-color: rgba(255, 255, 255, 0.2);
    color: white;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

button:hover {
    background-color: rgba(255, 255, 255, 0.3);
}

.glass-form-container {
    margin-top: 100px;
    position: relative;
    width: 100%;
    padding: max(5vw, 25px);
    background: rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(10px);
    border-radius: 10px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.glass-form-container h2 {
    color: white;
    text-align: center;
    margin-bottom: 1em;
}

.confirmation-message {
    display: none;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: rgba(255, 255, 255, 0.9);
    backdrop-filter: blur(5px);
    border-radius: 5px;
    padding: 1rem;
    width: 100%;
    max-width: 500px;
    text-align: center;
    border: 1px solid #d6a459;
    color: #d6a459;
}

.confirmation-message.active {
    display: block;
}