.interstitial-section h2:nth-last-child() {
    margin-bottom: 0px;
}

.interstitial-section button {
    padding: 1rem;
    border: none;
    border-radius: 5px;
    background-color: rgba(255, 255, 255, 0.2);
    color: white;
    cursor: pointer;
    transition: background-color 0.3s ease;
    max-width: 30vw;
}

.button {
    height: 100%;
    padding: 1rem;
    border: none;
    border-radius: 5px;
    background-color: rgba(255, 255, 255, 0.2);
    color: white;
    cursor: pointer;
    transition: background-color 0.3s ease;

    padding: 1rem;
    cursor: pointer;
    text-align: center;
    text-transform: none;
    font-size: 13px;
    max-width: 30vw;
}

.interstitial-section button:hover {
    background-color: rgba(255, 255, 255, 0.3);
}

.interstitial-section .button a:hover {
    background-color: rgba(255, 255, 255, 0.3);
}

.button-container {
    padding: 20px 0;
    display: flex;
    align-items: center;
    justify-content: space-around;
    gap: 10px;
}

.interstitial-section strong {
    line-height: 25px;
    margin-left: 50px;
}

@media (max-width: 650px) {

    .button-container {
        padding: 20px 0;
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
        gap: 10px;
        justify-items: center;
        align-items: center;
    }

    .button {
        max-width: 90vw;
    }

    .interstitial-section button {
        max-width: 90vw;
    }

}
