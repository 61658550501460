html {
    background: linear-gradient(-45deg, #d6cc9d, #ffcf68, #d6cc9d, #fff3c0);
	background-size: 600% 200%;
	animation: gradient 20s ease infinite;
}

body {
	height: 100%;
    padding: 10px;
    overflow-x: hidden;
}

@keyframes gradient {
	0% {
		background-position: 0% 50%;
	}
	50% {
		background-position: 100% 50%;
	}
	100% {
		background-position: 0% 50%;
	}
}