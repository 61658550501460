.interstitial-section {
    color: white;
    text-align: center;
    padding: 2.5rem 4rem;
    width: 100%;
    margin-top: 100px;

    background: rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(10px);
    border-radius: 10px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.interstitial-section h2 {
    color: white;
    font-weight: normal;
    line-height: 40px;
    margin-bottom: 20px;
    font-size: bold;
}

.interstitial-section h2:nth-last-child() {
    margin-bottom: 0px;
}
